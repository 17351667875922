.popup2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.popup-inner2 {
  background-color: #ffffff; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  height: 80%;
  padding: 20px;
  border-radius: 8px;
}

.popup-message {
  margin: 0;
  font-size: 22px;
  font-weight: 900;
  text-align: center;
}

.cartpopup-close2 {
  width: 100px;
  margin-top: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textarea {
  border-style: solid;
  border-width: 2px;
  border-color: black;
  border-radius: 5px;
}

.inputheader {
  font-size: 16px;
  font-weight: bold;
}

.biggerheader {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.values1 {
  font-size: 20px;
  font-weight: bold;
  
}

.values2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: -10px;
}
