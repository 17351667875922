/* contactstats.css */

.contactStatsMain {
    text-align: center;
    margin-top: 110px;
  }
  
  .contactStatsSecond {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  .contactStatsSecond > div {
    display: flex;
    flex-direction: column;
    text-align: left;
    border: 1px solid #ccc;
    padding: 10px;
    width: 300px; /* Adjust the width as needed */
    box-sizing: border-box;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.338);
    cursor: pointer;
  }

  .contactStatsSecond > div:hover {
    background-color: rgba(0, 0, 0, 0.134);
  }

  .contactStatsThird {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    margin: 30px;
  }

  .contactStatsHeader {
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.338);
    box-sizing: border-box;
    min-width: 100px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .contactStatsHeader:hover {
    background-color: rgba(0, 0, 0, 0.134);
  }

  .contactStatsName {
    font-weight: bolder;
  }
  
  .over30Days {
    background-color: rgba(255, 204, 0, 0.395);
    animation: zoomInOut 2s infinite alternate; /* Adjust the duration as needed */
  }

  .contactStatsNew {
    font-weight: bolder;
    color: rgb(7, 190, 7);
    box-sizing: border-box;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .contactStatsNewBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }

  .contactStatsThirdColumn{
    display: flex;
    flex-direction: column;
  }

  .exportButton {
    font-size: 14px;
    margin-bottom: 10px;
    border: solid;
    border-color: black;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    padding: 5px;
  }

  .exportButton:hover {
    background-color: black;
    color: white;
  }
  
  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.05); /* Adjust the scale factor for zooming in */
    }
  }
  
  
  