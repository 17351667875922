.cartpage {
  background-color: #81817b19;
  height: 100%;
  margin-top: 100px;
  padding-top: 20px;
  padding-bottom: 100px;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

  }
  
  .sort {
    height: 40px;
  }

  .cartheader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }

  .kgvalue {
    font-size: 18px;
    height: 100px;
    width: 120px;
    margin: 20px;
    border: 1px;
    border-radius: 5px;
    border-style: solid;
    padding: 10px;
    background-color: black;
    color: white;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.3);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  select {
    height: 40px;
    margin: 20px;
    border: 1px;
    border-radius: 5px;
    border-style: solid;
    padding: 10px;
    font-size: 12px;
    background-color: black;
    color: white;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.3);
  }
  

  .newcard {
    width: 50%;
    height: 50%;
    background-color: rgba(0, 0, 0, 0.147);
    border-radius: 5px;
    border: 1px solid black;
    margin: 10px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 10px 2px rgba(0, 0, 0, 0.3);
  }

  .newcard img {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.147);
    border-radius: 5px;
    border: 1px solid black;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 10px 2px rgba(0, 0, 0, 0.3);
  }
  
  .bottombutton {
    margin-bottom: 50px;
    background-color: white;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
    width: 150px;
    background-color: #3d3d3519;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.3);
  }

  .bottombutton:hover {
    background-color: black;
    color: white;
  }

  .bottomgroup {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }



  @media (max-width: 748px) {
    .newcard {
      width: 100%;
      height: 100%;
      padding: 10px;
    }
    .newcard img {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.147);
        border-radius: 5px;
        border: 1px solid black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 10px 10px 2px rgba(0, 0, 0, 0.3);
      }

      .cartpage {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }

      .kgvalue {
        font-size: 10px;
        margin: 20px;
        border: 1px;
        border-radius: 5px;
        border-style: solid;
        padding: 5px;
        background-color: black;
        color: white;
        box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.3);
      }
    
      select {
        height: 40px;
        margin: 20px;
        border: 1px;
        border-radius: 5px;
        border-style: solid;
        padding: 10px;
        font-size: 10px;
        background-color: black;
        color: white;
        box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.3);
      }

      .hidden-on-small-screen {
        display: none;
      }
      
      
  
  }