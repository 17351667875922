.salesLoginMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .salesLoginSecond {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 200px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.338);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.164);
  }