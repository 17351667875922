.calendarMain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 150px;
  }
    
    .contactListSecond {
      border: 1px solid #ccc;
      padding: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
      border-radius: 5px;
      display: flex;
      height: 60%;
      overflow-y: auto; /* Add a vertical scrollbar if content overflows */
      flex-direction: column;
      margin-bottom: 20px;
      justify-content: center;
      align-items: center;
    }


.react-calendar {
    font-size: 12px; 
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;

  }
  
  .react-calendar__month-view__days__day {
    min-height: 50px; 
    display: flex;
    flex-direction: column;
    font-size: 10px; 
    align-items: left;   
  }
  
  .event-marker {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2px;
    font-size: 10px; 
  }
  
  .event-marker > div {
    margin-bottom: 4px;
    font-size: 10px; /* Adjust font size as needed */
    line-height: 1.2; /* Adjust line height as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
  }
  
  /* Add these styles to your CSS file */
.contact-event {
    padding: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .call {
    background-color: #63a69fa5; /* Change the color as needed */
  }
  
  .visit {
    background-color: #f9a8257c; /* Change the color as needed */
  }
  
  .delivery {
    background-color: #27b8117e; /* Change the color as needed */
  }

  .visitIcon {
    margin-left: 4px;
    cursor: pointer;
  }
  
  .visitIcon:hover {
    transform: scale(1.2);
  }

