.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .cartpopup-inner {
    background-color: #d28753; 
    padding: 20px;
    border-radius: 8px;
    width: 60%;
  }
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
  }

  .flag-admin {
    color: black;
    margin-right: 20px;
    cursor: pointer;
    width: 40px;
    margin-top: 10px;
    border-radius: 5px;
  }
  