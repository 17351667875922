.goal-charts-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    background-color: black;
    padding: 20px;
    margin: 20px;
    border: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
}

.goal-charts-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: black;
    padding: 20px;
    margin: 20px;
    border: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    color: white;
    margin-top: 120px;
}


@media screen and (max-width: 540px) {


    .goal-charts-main {
        display: none;
    }
  }