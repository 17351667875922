.cartpopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.926);
  }
  
  .cartpopup-inner {
    background-color: #ffffff; 
    padding: 10px;
    border-radius: 8px;
    width: 90%;
    justify-content: center;
  }
  
  
  .cartpopup-message {
    margin: 0;
    font-size: 22px;
    font-weight: d00;
    text-align: center;
  }
  
  .cartpopup-close {
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 350px) {
  .accessbutton {
    font-size: 10px;
  }
  }