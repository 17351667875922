/* footer.css */

.footer-container {
  display: flex;
  justify-content: space-between;
  background-color: #333;
  color: #fff;
  bottom: 0;
  width: 100%;
}

.footer-column {
  flex: 1;
  margin: 20px;
}

.flags {
  width: 30px;
}

.button-countries {
  margin: 10px 0; 
  background: black;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  padding: 20px;
  font-size: 16px;
  width: 150px; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
}

.button-countries:hover {
  background: rgb(80, 80, 80);
}

.button-countries-cancel {
  margin: 10px 0; 
  background: black;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  padding: 20px;
  font-size: 16px;
  width: 150px; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 20px;
}

.button-countries-cancel:hover {
  background: rgb(80, 80, 80);
}


/* Add responsive styles */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }
}


.info-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.info-icons {
  color: black;
  margin: 10px;
  size: 50px;
}

.whatsapp-icon {
  color: white;
  width: 40px;
  height: 40px;
}

.whatsapp-icon:hover {
  color: rgb(21, 252, 45);
  width: 42px;
  height: 42px;
}

.whatsapp-link {
  text-decoration: underline;
  cursor: pointer; /* Add this to make it clear it's clickable */
}
