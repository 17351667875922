.calculatorMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 100px;
  }
  
  .calculatorSecond {
    display: flex;
    flex-direction: row;
    margin: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .calculatorTitle {
    display: flex;
    flex-direction: row;
    margin: 20px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 36px;
  }
  
  .RH {
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    background-color: rgba(29, 24, 24, 0.518);
    margin: 5px;
    border-radius: 10px;
  }

  .PD {
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    background-color: rgba(29, 24, 24, 0.518);
    margin: 5px;
    border-radius: 10px;
  }

  .PT {
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    background-color: rgba(29, 24, 24, 0.518);
    margin: 5px;
    border-radius: 10px;
  }

.PT input {
  margin: 5px;
  /* Add any additional styling for the inputs */
}

.PD input {
    margin: 5px;
    /* Add any additional styling for the inputs */
  }

  .RH input {
    margin: 5px;
    /* Add any additional styling for the inputs */
  }

  .Tags {
    margin: 5px;
  }

  @media screen and (max-width: 768px) {
  .calculatorSecond {
    display: flex;
    flex-direction: column;
  }
}
  