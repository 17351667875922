.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .popup-inner {
    background-color: #ffffff; 
    padding: 20px;
    border-radius: 8px;
    animation: pulse 1.5s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .popup-message {
    margin: 0;
    font-size: 22px;
    font-weight: 900;
    text-align: center;
  }
  
  