.prmetalsmain {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.pageheader {
    background-color: rgb(0, 0, 0);
    border-top: 2px solid #ffffff;
    color: white;
    width: 100%;
    font-size: 28px;
    margin-top: 20px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.simpletext {
    margin-top: 20px;
    color: black;
    border-radius: 5px;
    border-width: 2px;
    border-color: black;
    border-style: solid;
    padding: 10px;
    box-shadow: -2px 5px 5px -2px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 600px) {

    .simpletext {
        margin-top: 20px;
        color: black;
        border-width: 2px;
        padding: 5px;
        font-size: 12px;
    } 
 }
