.contactDetailsMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.contactDetailsSecond {
  margin-top: 20px;
  width: 95%;
  border-style: solid;
  padding: 10px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
  font-size: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.contactDetailsNotesMain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  flex-direction: column;
  margin-top: 15PX;
  margin-bottom: 40px;
}

.contactDetailsBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
}

.contactDetailsNotesBox {
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
  border-radius: 5px;
  display: flex;
  max-height: 400px; 
  min-width: 100%;
  height: 60%;
  overflow-y: auto; /* Add a vertical scrollbar if content overflows */
  flex-direction: column;
  align-items: left;
}

.trashicon {
  color: red;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.trashicon:hover {
  color: rgb(0, 0, 0);
  transform: scale(1.2);
}

.noteSeparator {
  margin-bottom: 10px;
  padding-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.34);
  border-radius: 5px;
}

.noteContent {
  display: flex;
  margin-top: 4px;
}

.addNote {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin-bottom: 20px;
}

.contactResponsibleBox {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: center; /* Center items horizontally */
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
  text-align: center; 
  font-size: 10px;
}

.contactIconBox {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
  display: flex;
}

.noContactButton {
  border-width: 0px;
}

.contactIconBoxMain {
  display: flex;
}

.iconMaterial {
  color: rgb(28, 172, 9);
  margin-left: 2px;
  margin-right: 2px;
}

.iconChart {
  color: rgb(28, 172, 9);
  margin-left: 2px;
  margin-right: 2px;
}

.iconChart:hover {
  transform: scale(1.2);
  cursor: pointer;
}


.contactMaterialBox {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
  display: flex;
  justify-content: center;
  align-items: center;
}

.editIconMaterial {
  color: rgb(0, 0, 0);
  margin-left: 5px;
  cursor: pointer;
}

.editIconMaterial:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.editIconCatalogue {
  color: rgb(0, 0, 0);
  margin-left: 5px;
  margin-top: 3px;
  cursor: pointer;
}

.editIconCatalogue:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.plusicon {
  color: rgb(28, 172, 9);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.plusicon:hover {
  color: rgb(0, 0, 0);
  transform: scale(1.2);
}

.editIcon {
  color: rgb(0, 0, 0);
  margin-left: 5px;
  cursor: pointer;
}

.editIcon:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.weightEntryBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.weightEntry {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.ulAssignUser {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  text-align: left;
}

/* Add this CSS to highlight the input in red when it's empty */
.weightInput.error {
  border: 1px solid red;
}


.plusiconmodal {
  margin-left: 10px;
  color: rgb(28, 172, 9);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.plusiconmodal:hover {
  color: rgb(0, 0, 0);
  transform: scale(1.2);
}

.buttonModalAssignUser {
  width: 100px;
}

.modaluserlist {
  padding: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}

.modaluserind {
  padding: 5px;
}

.react-calendar__tile:hover {
  background-color: transparent !important;
  color: black;
}

.contact-event:hover {
  background-color: black;
  color: white;
}

@media screen and (max-width: 940px) {

  .contactIconBoxMain {
    display: flex;
    flex-direction: column;
  }
  .contactIconBox {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}



@media screen and (max-width: 840px) {

  .contactDetailsBox {
    font-size: 10px;
    padding: 5px;
    display: flex;
    flex-direction: row;
  }

  .contactIconBox {
    font-size: 10px;
  }
  
  .noContactButton {
    font-size: 14px;
  }
  
  .contactIconBoxMain {
    display: flex;
  }
    
  .contactMaterialBox {
    font-size: 14px;
    
  }
}

@media screen and (max-width: 840px) {

  .contactDetailsNotesMain {
    font-size: 12px;
  }

  .contactDetailsBox {
    font-size: 12px;
    padding: 5px;
    display: flex;
    flex-direction: column;
  }

  .contactIconBox {
    font-size: 10px;
  }
  
  .noContactButton {
    font-size: 12px;
  }
  
  .contactIconBoxMain {
    display: flex;
  }
    
  .contactMaterialBox {
    font-size: 12px;
    
  }
}

@media screen and (max-width: 540px) {

  .contactDetailsNotesMain {
    font-size: 10px;
    padding: 2px;
    width: 90%;
  }

  .contactDetailsSecond {
    margin-top: 10px;
    width: 90%;
  }

  .contactDetailsBox {
    font-size: 10px;;
    padding: 3px;
    display: flex;
    flex-direction: column;
  }

  .contactResponsibleBox {
    font-size: 10px;
  }

  .contactIconBox {
    font-size: 10px;
  }
  
  .noContactButton {
    font-size: 10px;
  }
  
  .contactIconBoxMain {
    display: flex;
  }
    
  .contactMaterialBox {
    font-size: 10px;
    padding: 5px;
    margin: 5px;
  }
  .addNote {
    width: 90%;
  }

  .modaluserlist {
    padding: 5px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 12px;
  }

  .modaluserind {
    width: 150px;
    margin-left: 40px;
  }
}

@media screen and (max-width: 430px) {

  .contactDetailsNotesMain {
    font-size: 8px;
    padding: 2px;
  }

  .contactDetailsBox {
    font-size: 8px;
    padding: 3px;
    display: flex;
    flex-direction: column;
  }

  .contactResponsibleBox {
    font-size: 8px;
  }

  .contactIconBox {
    font-size: 8px;
  }
  
  .noContactButton {
    font-size: 8px;
  }
  
  .contactIconBoxMain {
    display: flex;
    flex-direction: column;
  }
    
  .contactMaterialBox {
    font-size: 8px;
    padding: 5px;
    margin: 5px;
  }

  .weightInput {
    width: 80%;
  }
}