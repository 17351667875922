.weemainhome {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  justify-content: center;
}

.weesecondhome {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 90%; 
  margin-top: 150px;
}

.card-containerhome {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%; /* Occupy full width */
}

  .card h3 {
    margin-top: 0;
  }
  
  .card img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    max-width: 80%;
    max-height: 80%;
    border-radius: 5px;
  }
  
  .card p {
    margin: 0;
  }
  
  .button {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  /* Adjust the size of the Slider container */
  .slider-container {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }
  
  .slider-container:hover {
   border-width: 1px;
   border-color: rgba(0, 0, 0, 0);
   border-style: solid;
  }
  
  /* Adjust the size of the images within the Slider */
  .slider-container .slick-slide img {
    margin-left: 30px;
    max-width: 80%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    object-fit: cover; /* Ensure the image fills the container while maintaining aspect ratio */
  }
  
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    z-index: 9999;
    overflow-y: auto; /* Add this line to enable scrolling */
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.885);
    z-index: 9998;
  }
  
  .slick-prev,
  .slick-next {
    color: black;
  }
  
  .slick-prev:before,
  .slick-next:before {
    color: black;
    font-size: 20px;
  }
  
  
  .enlarged-image {
  
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .modal-content {
    max-width: 80%;
    max-height: 80%;
    margin: auto;
    position: relative;
  }
  
  .close {
    position: absolute;
    top: -10px;
    right: -20px;
    font-size: 30px;
    background-color: black;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .close:hover {
    color: #ccc;
  }
  
  .search-container {
    margin-bottom: 10px;
  }
  
  
  
  @media screen and (max-width: 350px) {
  .card {
    width: 250px;
    padding: 10px;
  }
  }

  @media (max-width: 800px) {
    .card-containerhome {
      flex-direction: column;
    }
  }
/* Set initial color to black */
.comment-icon {
    color: #ffffff;
  }
  
  /* Change color to white on hover */
  .floating-icon:hover .comment-icon {
    color: #e39b14;
  }

  
  


  