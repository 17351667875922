.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start; /* Align items to the start of the flex container */
}

.item {
  width: 100%;
  margin: 10px;
  box-sizing: border-box;
  overflow: hidden;
  border: 8px solid transparent;
  border-image: linear-gradient(to right, 
    #d28753 0%, 
    #4f4c4c 10%, 
    white 50%, 
    rgb(153, 109, 5) 90%, 
    #d28753 100%
  );
  border-image-slice: 1;
  border-radius: 10px;
  padding: 10px;
  color: black;
  border: 8px solid transparent; /* Set the border to transparent */
  border-image: linear-gradient(to right, 
    #d28753 0%, 
    #4f4c4c 10%, 
    white 50%, 
    rgb(153, 109, 5) 90%, 
    #d28753 100%
  );
  border-image-slice: 1;
  border-radius: 10px;
  color: black;
  animation: colorTransition 8s linear infinite, pulsate 4s ease-in-out infinite;
  position: relative;
}
  
  @keyframes colorTransition {
    0%, 100% {
      border-image-source: linear-gradient(to right, 
        #d28753 0%, 
        #4f4c4c 10%, 
        white 50%, 
        rgb(153, 109, 5) 90%, 
        #d28753 100%
      );
    }
    25% {
      border-image-source: linear-gradient(to right, 
        #4f4c4c 0%, 
        white 10%, 
        rgb(171, 105, 14) 50%, 
        #d28753 90%, 
        #4f4c4c 100%
      );
    }
    50% {
      border-image-source: linear-gradient(to right, 
        white 0%, 
        rgb(171, 105, 14) 10%, 
        #d28753 50%, 
        #4f4c4c 90%, 
        white 100%
      );
    }
    75% {
      border-image-source: linear-gradient(to right, 
        rgb(171, 105, 14) 0%, 
        #d28753 10%, 
        #4f4c4c 50%, 
        white 90%, 
        rgb(171, 105, 14) 100%
      );
    }
  }
  
  @keyframes pulsate {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.02);
    }
  }
  
  .item.catalytic:hover {
    animation: none; /* Disable the animation on hover */
    border-image-source: linear-gradient(to right, #d28753, #000);
    cursor: pointer;
    background-color: black;
    color: white;
    border: 8px solid black;
  }
  
  .item.electronic:hover {
    animation: none; /* Disable the animation on hover */
    border-image-source: linear-gradient(to right, #d28753, #000);
    cursor: pointer;
    background-color: black;
    color: white;
    border: 8px solid black;
  }
  
  .item.catalytic {
    animation: colorTransition 8s linear infinite, pulsate 4s ease-in-out infinite; /* Set the duration as needed */
  }
  
  .item.electronic {
    animation: colorTransition 8s linear infinite, pulsate 4s ease-in-out infinite;
    animation-delay: 2s; /* Delay the pulsate animation for 2 seconds */
  }
  
  
  .item .content {
    display: flex;
    /* Remove flex-direction: column; to display as rows */
  }
  
  .item.catalytic .content {
    flex-direction: row;
  }
  
  .item.electronic .content {
    flex-direction: row-reverse; /* Reverse the order for electronic items */
  }
  
  .item img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  @media (max-width: 800px) {
    .item {
      width: 90%;
    }
    .description {
      font-size: 12px;
    }

    .item.catalytic .content {
      flex-direction: column;
    }
    
    .item.electronic .content {
      flex-direction: column; /* Reverse the order for electronic items */
    }
  }
  