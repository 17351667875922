.debt-breakdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 40px;
  }

  .debt-breakdown-Second {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 5px;
  }
  
  .debt-table {
    border-collapse: collapse;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    width: 120vh;
  }
  
  .debt-table th, .debt-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    border-radius: 10px;
    width: 100vh;
  }
  
  .debt-table th {
    background-color: #000000;
  }


.debt-breakdown-container {
    position: relative;
  }

  .visitDebtIcon {
    margin-left: 4px;
    cursor: pointer;
  }
  
  .visitDebtIcon:hover {
    transform: scale(1.4);
    color: rgb(3, 81, 3);
  }

  @media screen and (max-width: 820px) {
    .debt-table th, .debt-table td {
      font-size: 14px;
      padding: 2px;
      flex-direction: column;
      white-space: normal;
    }
    
    .debt-table {
        width: 100vh;
    }
  }

  
  @media screen and (max-width: 600px) {
    .debt-table th, .debt-table td {
      font-size: 12px;
      padding: 2px;
      flex-direction: column;
      white-space: normal;
    }
    
    .debt-table {
        width: 80vh;
    }
  }

  @media screen and (max-width: 540px) {
    .debt-table th, .debt-table td {
      font-size: 12px;
      padding: 2px;
      flex-direction: column;
      white-space: normal;
    }
    
    .debt-table {
        width: 60vh;
    }
  }

  @media screen and (max-width: 400px) {
    .debt-table th, .debt-table td {
      font-size: 10px;
      padding: 2px;
      flex-direction: column;
      white-space: normal;
    }
    
    .debt-table {
        width: 50vh;
    }
  }
  
  