/* Base styles for larger screens */
.contactsMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactsSecond {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 100px;
}

.customersMain {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.338);
  border-radius: 10px;
  margin-top: 20px;
}

.contactButton {
  margin: 10px;
  border-width: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.199);
  padding: 10px;
}

.userMainList {
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 40px;
}

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 8px 0;
}

.table-row.header {
  font-weight: bold;
}

.table-cell {
  flex: 1;
  padding: 0 8px;
}

.yellowBackground {
  background-color: yellow;
}

.redBackground {
  background-color: red;
}

.trashicon {
  color: red;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.trashicon:hover {
  color: rgb(0, 0, 0);
  transform: scale(1.2);
}

.viewuseradmin {
  color: black;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #3d3d3519;
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.3);
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {

  .viewuseradmin  {
    font-size: 10px;
    margin: 10px;
  }

  .contactButton  {
    font-size: 10px;
    margin: 5px;
  }

  .userMainList {
    font-size: 10px;
    margin: 10px;
  }
}
