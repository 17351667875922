.userStatsChart {
    margin-top: 100px;
    background-color: rgb(0, 0, 0);
  }

  .userStatsChartSecond {
    margin-top: 100px;
    background-color: rgb(0, 0, 0);
    display: flex;
    justify-content: space-evenly;
    color: white;
  }

  .customer-info-container-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;    
    color: white;
  }
  

  .customer-info-container {
    display: flex;
    flex-direction: row;
    text-align: center;     
    color: white;
  }

  .customer-info-column {
    color: white;
    margin: 5px;
    min-width: 300px;
    padding: 10px;
  }

  .customer-info-row {
    color: white;
    margin: 5px;
    min-width: 300px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .contactUserStatsInd {
    background-color: rgb(24, 57, 73);
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
  }

  .contactUserStatsInd:hover {
    background-color: rgb(9, 21, 27);
    cursor: pointer;
  }

  .contactUserStatsIndWorse {
    background-color: rgb(107, 17, 23);
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
  }

  .contactUserStatsIndWorse:hover {
    background-color: rgb(164, 25, 34);
    cursor: pointer;
  }

  .contactUserStatsIndTop {
    background-color: rgb(6, 76, 21);
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
  }

  .contactUserStatsIndTop:hover {
    background-color: rgb(7, 124, 32);
    cursor: pointer;
  }

  .contactUserStatsIndAttention {
    background-color: rgb(212, 143, 4);
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
  }

  .contactUserStatsIndAttention:hover {
    background-color: rgb(255, 180, 30);
    cursor: pointer;
  }

  .userStatsCustTitle {
    color: white;
    font-size: 20px;
    font-weight: bold;
  }

  .userstatscustheader {
    font-size: 16px;
    font-weight: bold;
  }

  .exportbutton {
    cursor: pointer;
    border-radius: 5px;
    margin: 10px;
    border-width: 0px;
    width: 100px;
    box-shadow: 0 4px 8px rgba(153, 153, 153, 0.199);
    padding: 10px;
    background-color: white;
    color: black;
  }


  @media screen and (max-width: 1000px) {
    .customer-info-container {
      display: flex;
      flex-direction: column;
      text-align: center;    
      color: white;
    }
  }

  @media screen and (max-width: 700px) {
    .exportbutton {
      cursor: pointer;
      border-radius: 5px;
      margin: 10px;
      border-width: 0px;
      width: 80px;
      box-shadow: 0 4px 8px rgba(153, 153, 153, 0.199);
      padding: 5px;
      background-color: white;
      color: black;  
      font-size: 12px;
    }

    .customer-info-row {
      color: white;
      margin: 5px;
      max-width: 300px;
      padding: 0px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .contactUserStatsInd {
      max-width: 300px;
    }

    .contactUserStatsIndAttention {
      max-width: 300px;
    }

    .contactUserStatsIndWorse {
      max-width: 300px;
    }

    .contactUserStatsIndTop {
      max-width: 300px;
    }
  
  }