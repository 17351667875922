.tasks-container {
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 5px;
  margin: 5px;
  border: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
  color: white;
  margin-top: 110px;
  height: 100vh;
  margin-bottom: 15px;
  }

  .tasksTable {
    border-radius: 5px;
    border: solid;
    border-color: black;
    background-color: rgba(29, 84, 92, 0.226);
    margin-top: 20px;
    border-top: solid;
    border-top-color: rgb(179, 195, 35);
  }

  .inputTaskDetails {
    color: grey;
    border-color: grey;
  }

  .tasks-container-row {
    display: flex;
    flex-direction: row;
  }

  .tasks-container-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .inputTask {
    background-color: black;
    color: white;
    border: solid;
    border-color: white;
    border-width: 1px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    font-size: 14px;
    width: 280px;
    height: 20px;
    font-style: italic;
  }

  .stepButton {
    background-color: rgb(202, 222, 20);
    color: rgb(0, 0, 0);
    border: solid;
    border-color: white;
    border-width: 1px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    font-size: 14px;
    width: 150px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    font-weight: 600;
  }

  .stepButton:hover {
    background-color: white;
    color: black;
  }

  .selectTask {
    background-color: black;
    color: white;
    border: solid;
    border-color: white;
    border-width: 1px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    font-size: 12px;
    width: 300px;
  }

  .inputTask::placeholder {
    color: rgb(248, 248, 248);
}

.inputTask::-webkit-calendar-picker-indicator {
    filter: invert(1); 
    cursor: pointer;
}

.progress-bar-container {
    width: 100%;
    height: 20px;
    background-color: lightgray;
    border-radius: 5px;
  }
  
  .progress-bar {
    height: 100%;
    border-radius: 5px;
    color: white;
    text-align: center;
    line-height: 20px;
  }

  .modalSteps {
    color: white;
    font-size: 30px;
    margin: 20px;
  }

  .modalStepsTickBox {
    font-size: 12px;
    color: white;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .modal-task-steps-body-ticks {
    display: flex;
    flex-direction: row;
    background-color: rgb(46, 46, 51);
    margin: 20px;
    border-radius: 5px;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }

  .modalStepsTickBoxTitle {
    font-size: 16px;
    color: white;
    cursor: pointer;
  }

  .modal-task-steps-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .modal-task-steps-body-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .stepClickOpenModal {
    cursor: pointer;
  }
  .stepClickOpenModal:hover {
    color: white;
    background-color: black;
  }

  .closeStepModal {
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: rgb(46, 46, 51);
    padding: 10px;
    font-size: 20px;
    border-radius: 5px;
    width: 150px;
    margin-top: 20px;
  }

  .closeStepModal:hover {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
  }



  @media screen and (max-width: 648px) {
  .inputTask {
    width: 200px;
  }

  .selectTask {
    width: 220px;
  }

  .tasks-container-row {
    display: flex;
    flex-direction: column;
  }
}