.dashmain {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
  }

.dashmaintoprow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.dashmaintopsecondrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menuDashboard {
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: space-around;
}

.menuDashboardColumn {
  display: flex;
  flex-direction: column;
  margin: 10px;
  justify-content: space-around;
}

.menuDashboardClicks {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 120px;
  font-size: 20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: solid;
  border-width: 1px;
  border-color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
}

.menuDashboardClicksHide {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100px;
  font-size: 20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: solid;
  border-width: 1px;
  border-color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
}

.menuDashboardClicksHide:hover {
  background-color: black;
  color: white;
}

.menuDashboardClicks:hover {
  background-color: black;
  color: white;
}

.dashboardIcons {
  width: 30px;
  height: 30px;
}
  
  .content {
    flex: 1;
  }

  .contentCenter {
    flex: 1;
    margin-top: 50px;
  }

  @media screen and (max-width: 1200px) {
    .menuDashboardClicksHide {
      display: none;
    }
    
  }
  
  
  