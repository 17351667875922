
.contactEditAdminMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }

  .contactEditAdminSecond {
    margin-top: 20px;
    width: 95%;
    border-style: solid;
    padding: 10px;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    font-size: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .contactEditAdminSecondBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    font-weight: 700;
  }

  .contactEditAdminMainList {
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 95%;
  }

  .contactEditAdminTableContainer {
    padding: 10px;
    display: flex;
    flex-direction: row;
    width: 95%;
    margin-top: 20px;
    justify-content: space-around;
  }

.table-container-editAdmin {
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .table-header,
  .table-row {
    display: flex;
    padding: 8px;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ddd;
  }
  
  .table-cell {
    flex: 1;
    text-align: left;
    padding: 8px;
  }
  
  .table-header {
    font-weight: bold;
  }
  
  .table-row:hover {
    background-color: #f9f9f9;
  }

  .trashicon {
    color: red;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .trashicon:hover {
    color: rgb(0, 0, 0);
    transform: scale(1.2);
  }

  .editicon {
    margin-left: 10px;
    cursor: pointer;
  }

  .editicon:hover {
    transform: scale(1.3);
  }
  

  .adminEditWarningInput {
    width: 50px;
    margin-left: 10px;
    text-align: center;
  }


  .warning-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    text-align: center;
  }
  
  .warning-popup p {
    margin-bottom: 20px;
  }
  
  .warning-popup button {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
  }
  
  .warning-popup button.yes {
    background-color: #d9534f;
    color: #fff;
    border: none;
  }
  
  .warning-popup button.no {
    background-color: #5bc0de;
    color: #fff;
    border: none;
  }

  .deleteAdminContactInput {
    margin-top: 10px;
  }
  

  @media screen and (max-width: 768px) {
  
    .contactEditAdminMainList {
      font-size: 12px;
      margin: 10px;
      padding: 5px;
    }

    .contactEditAdminSecond {
      font-size: 8px;
      margin: 5px;
      padding: 5px;
    }

    .contactEditAdminSecondBox {
      font-size: 8px;
      margin: 5px;
      padding: 5px;
    }
  }

  @media screen and (max-width: 450px) {


    .contactEditAdminSecond {
      font-size: 8px;
      margin: 5px;
      padding: 5px;
    }

    .contactEditAdminSecondBox {
      font-size: 8px;
      margin: 5px;
      padding: 5px;
      font-weight: 400;
    }
  }