/* CrgEgyptMain.css */

.crg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 40px;
  }
  
  .table-container {
    display: flex;
    flex-direction: column;
    border-collapse: collapse;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .table-crg {
    display: flex;
    flex-direction: column;
    min-width: 800px;
  }
  
  .row-container {
    display: flex;
  }
  
  .table-section {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    margin: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .table-section-debt {
    display: flex;
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    margin: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .table-section:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.221);
  }

  .table-section-debt:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.221);
  }
  
  .row-item {
    border-bottom: 1px solid #ddd;
    padding: 10px;
  }
  
  .row-item:last-child {
    border-bottom: none;
  }
  
  h1, h2 {
    color: #333;
  }
  
  p {
    margin: 5px 0;
  }
  
  .data-table th,
.data-table td {
  padding: 10px; /* Add padding to table cells for better readability */
  text-align: center; /* Center-align text within table cells */
  border: 1px solid #fff; /* Add border to table cells for better visibility */
}

  @media screen and (max-width: 600px) {
    .row-item {
      font-size: 14px;
    }
    .data-table th,
    .data-table td {
      font-size: 14px; 
      padding: 5px;
    }

    .table-crg {
      display: flex;
      flex-direction: column;
      min-width: 0%;
    }

  }
  

  
  