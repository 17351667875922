.numberSearchMain {
    margin-bottom: 10px;
  }

  .numberSearchMain input.customerSearch {
    padding: 8px;
    margin-right: 10px;
  }
  
  .numberSearchMain button {
    padding: 8px 12px;
  }
  
  .modalContent {
    text-align: center;
  }
  
  .buttonModalAssignUser {
    padding: 8px 12px;
    margin-top: 10px;
  }
  
  