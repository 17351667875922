.exportmain {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
  }

  .exportmainform {
    display: flex;
    flex-direction: column;
    margin-top: 150px;
  }


  .exportCreateLoadButton {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 150px;
    font-size: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid;
    border-width: 1px;
    border-color: white;
    box-shadow: 0 4px 8px rgba(57, 57, 57, 0.76);
    margin: 20px;
  }
  
  .exportCreateLoadButton:hover {
    background-color: rgb(255, 255, 255);
    color: black;    
  }

  .inputExportDetails {
    color: white;
    align-items: center;
    text-align: center;
    background-color: rgba(171, 171, 171, 0.268);
  }

  .inputExportDetailsDate {
    max-width: 100px;
    color: white;
    align-items: center;
    text-align: center;
    background-color: rgba(171, 171, 171, 0.268);
  }

  .inputExportDetailsFixations {
    color: white;
    align-items: center;
    text-align: center;
    background-color: rgba(171, 171, 171, 0.268);
  }

  .inputExportDetailsResults {
    color: white;
    align-items: center;
    text-align: center;
    background-color: rgba(171, 171, 171, 0.268);
    width: 90%;
  }

  .inputExportDetailsType {
    color: white;
    align-items: center;
    text-align: center;
    background-color: rgba(171, 171, 171, 0.268);
  }

  .exports-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: black;
    padding: 5px;
    margin: 5px;
    border: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    color: white;
    margin-top: 120px;
}


.formExpensesRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;
}

.formExpensesRowResponsive {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;
}


.report-view-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: black;
    padding: 10px;
    margin: 5px;
    border: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    color: white;
    margin-top: 120px;
}

.report-view-main-top-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: black;
    padding: 10px;
    margin: 5px;
    border: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    color: white;
}

.report-view-main-top-box {
    display: flex;
    flex-direction: column;
}

.report-view-main-middle-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.report-view-main-middle-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: black;
    padding: 10px;
    margin: 5px;
    border: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    color: white;
}

.report-view-main-middle-box {
    display: flex;
    flex-direction: column;
}

.exports-third-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.exports-third-row-loadview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.export-unused-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.export-unused {
    margin-left: 15px;
    border: solid;
    border-color: white;
    border-width: 1px;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(56, 56, 56, 0.76);
}

.export-unused-expenses {
    margin: 5px;
    border: solid;
    border-color: white;
    border-width: 1px;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(56, 56, 56, 0.76);
}

.h1HeadersExportDetails {
    color: rgba(255, 255, 255, 0.685);
    text-shadow: 4px 4px 2px rgba(107, 107, 107, 0.353);
}

.h1HeadersExportDetailsExpenses {
    color: rgba(255, 255, 255, 0.685);
    text-shadow: 4px 4px 2px rgba(107, 107, 107, 0.353);
}

.h1HeadersExportDetailsExpensesTitles {
    color: rgba(255, 255, 255, 0.685);
    text-shadow: 4px 4px 2px rgba(107, 107, 107, 0.353);
}

.exportDetailsTopRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.exportDetailsTopRowExpenses {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.table50 {
    background-color: rgba(29, 84, 92, 0.226);
    align-items: center;
    text-align: center;
}

.table50td {
    align-items: center;
    text-align: center;
    border-radius: 5px;
    border: solid;
    border-color: black;
}

.table50th {
    align-items: center;
    text-align: center;  
    width: 100px;
    background-color: rgba(29, 84, 92, 0.226);
    border-radius: 5px;
    border: solid;
    border-color: black;
}

.table50Pointer {
    border-radius: 5px;
    border: solid;
    border-color: black;
    background-color: rgba(29, 84, 92, 0.226);
    cursor: pointer;
}

.table50Pointer:hover {
    background-color: rgba(0, 255, 76, 0.484);
}

.table50Goal {
    border-radius: 5px;
    border: solid;
    border-color: black;
    background-color: rgb(0, 0, 0);
}

.inputExportDetailsGoal {
    color: grey;
    border-color: grey;
    width: 100px;
}

.exportExpensesBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    margin: 10px;
    background-color: rgba(30, 30, 30, 0.758);
    box-shadow: 0 4px 8px rgba(56, 56, 56, 0.76);
    width: 50%;
}

.exportExpensesBoxFixations {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    margin: 10px;
    background-color: rgba(30, 30, 30, 0.758);
    box-shadow: 0 4px 8px rgba(56, 56, 56, 0.76);
    width: 100%;
}

.exportExpensesBoxRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    margin: 10px;
    background-color: rgba(30, 30, 30, 0.758);
    box-shadow: 0 4px 8px rgba(56, 56, 56, 0.76);
    width: 98.5%;
    justify-content: center;
}

.exportExpensesBoxRowResponsive {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    margin: 10px;
    background-color: rgba(30, 30, 30, 0.758);
    box-shadow: 0 4px 8px rgba(56, 56, 56, 0.76);
    width: 98.5%;
    justify-content: center;
}

.table50header {
    text-align: center;
}

.xrfTables {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
}

.xrfExportResultsTableBody {
    width: 250px;
    margin-top: 5px;
    border: 5px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(5, 5, 5, 0.726);
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.xrfTablesHeaders {
    margin-top: 10px;
    text-shadow: 4px 4px 2px rgb(0, 0, 0);
}

.exports-third-column-small-screen {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.inputTaskExpenses {
    background-color: black;
    color: white;
    border: solid;
    border-color: white;
    border-width: 1px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    font-size: 14px;
    width: 280px;
    height: 20px;
    font-style: italic;
}

.selectTaskExpenses {
    background-color: black;
    color: white;
    border: solid;
    border-color: white;
    border-width: 1px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    font-size: 12px;
    width: 300px;
  }

  .expenseAllButton {
    background-color: rgba(0, 0, 0, 0.763);
    color: rgb(255, 255, 255);
    border: solid;
    border-color: white;
    border-width: 1px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    font-size: 12px;
    width: 150px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .expenseAllButton:hover {
    background-color: white;
    color: black;
  }

  .expenseAllButtonOpenCash {
    background-color: rgba(0, 0, 0, 0.763);
    color: rgb(255, 255, 255);
    border: solid;
    border-color: white;
    border-width: 1px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    font-size: 12px;
    width: 150px;
    height: 40px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    font-weight: 500;
  }

  .expenseAllButtonOpenCash:hover {
    background-color: white;
    color: black;
  }

  .calendar {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 6 boxes per row */
    gap: 10px; /* Adjust the gap between boxes */
}

.expense-item {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    text-align: center;
}

.expense-item:hover {
    background-color: rgb(0, 255, 76);
}

.expense-item-report  {
    background-color: rgba(20, 20, 20, 0.51);
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(86, 240, 245, 0.76);
    width: 200px;
    margin: 10px;
}

.expense-item-report-click  {
    background-color: rgba(20, 20, 20, 0.51);
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(86, 240, 245, 0.76);
    width: 200px;
    margin: 10px;
    cursor: pointer;
}

.expense-item-report-click:hover {
    background-color: rgba(0, 255, 76, 0.484);
}

.calendar-add-expense {
    grid-column: 1 / span 6; /* Span the entire row */
    text-align: center;
}

.add-expense {
    background-color: rgba(0, 255, 76, 0.571);
    font-size: 25px;
}

.add-expense:hover {
    background-color: rgb(0, 255, 76);
    font-size: 25px;
}

.exportExpensesBoxMainGoal {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    margin: 5px;
    background-color: rgba(30, 30, 30, 0.758);
    box-shadow: 0 4px 8px rgba(56, 56, 56, 0.76);
}

.bottomButtonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.red-background {
    background-color: red;
  }
  

.tableStock {
    border-radius: 5px;
    border: solid;
    border-color: black;
    background-color: rgba(29, 84, 92, 0.226);
}

.inputStockDetails {
    color: white;
    align-items: center;
    text-align: center;
    background-color: rgba(171, 171, 171, 0.166);
    font-size: 12px;
    height: 10px;
    max-width: 10px;
    word-wrap: break-word;
  }


  .tableExpensesContainer {
    width: 100%;
    border-radius: 5px;
    border: solid;
    border-color: black;
    background-color: rgb(72, 104, 109);
  }
  
  .tableExpensesRow {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(217, 217, 28, 0.568);
  }
  
  .tableExpensesColumnHeader {
    flex: 1;
    font-weight: bold;
    border-bottom: 1px solid black;
    padding: 8px;
    background-color: black;
  }
  
  .tableExpensesColumn {
    flex: 1;
    padding: 8px;
    border-left: 1px solid rgba(0, 0, 0, 0.205);
  }
  



@media screen and (max-width: 648px) {
    .export-unused-expenses {
        font-size: 10px;
        padding: 2px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .exportDetailsTopRowExpenses {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .formExpensesRowResponsive {
        display: flex;
        flex-direction: column;
    }

    
.exportExpensesBoxRowResponsive {
    display: flex;
    flex-direction: column;
    align-items: center;
}

    .exports-second {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 2px;
        margin-top: 100px;
    }

    .exportExpensesBox {
        width: 100%;
    }

    .table50 {
        width: 100%;
    }

    .table50Pointer {
        width: 100%;
    }

    .table50Goal {
        width: 100%;
    }

    .inputExportDetails {
        width: 100%;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 12px;
        padding: 10px;
      }

      .inputExportDetailsDate {
        max-width: 50px;
        font-size: 12px;
      }
    

      .inputExportDetailsFixations {
        width: 20px;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 8px;
        padding: 5px;
      }

      .inputExportDetailsType {
        min-width: 120px;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 10px;
        text-wrap: wrap;
      }
      
      

      .inputTaskExpenses {
        font-size: 12px;
        width: 220px;
        height: 15px;
    }

    .selectTaskExpenses {
        font-size: 12px;
        width: 240px;
      }
    
      .calendar {
        display: flex;
        flex-wrap: wrap;
    }

    .expense-item-report-click  {
        padding: 5px;
        font-size: 8px;
        width: 100px;
        margin: 5px;
        box-shadow: 0 2px 4px rgba(86, 240, 245, 0.76);
    }

    .expense-item-report  {
        padding: 5px;
        font-size: 8px;
        width: 100px;
        margin: 5px;
        box-shadow: 0 2px 4px rgba(86, 240, 245, 0.76);
    }

    .h1HeadersExportDetailsExpensesTitles {
        font-size: 8px;
        text-shadow: 2px 2px 2px rgba(107, 107, 107, 0.353);
    }

    .h1HeadersExportDetailsExpenses {
        font-size: 14px;
        text-shadow: 2px 2px 2px rgba(107, 107, 107, 0.353);
    }

    .exportCreateLoadButton {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 75px;
        font-size: 10px;
        padding: 10px;
        margin: 10px;
      }

      .exportDetailIcons {
        width: 15px;
        height: 15px;
      }

      .exports-third-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .exportDetailsTopRow {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .table50th {
        align-items: center;
        text-align: center;  
        width: 100px;
        font-size: 10px;
        padding: 2px;
    }

    .export-unused {
        margin-left: 5px;
        padding: 5px;
        font-size: 12px;
        align-content: center;
        width: 90px;
        margin-bottom: 10px;
    }
    
    .exports-third-row-loadview {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
  }

  @media screen and (max-width: 350px) {
    .export-unused-expenses {
        font-size: 8px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
    }

    .export-unused {
        margin-left: 5px;
        padding: 5px;
        font-size: 10px;
        align-content: center;
        width: 80px;
        margin-bottom: 10px;
    }
}