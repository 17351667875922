/* contactList.css */

.contactListMain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 110px;
}
  
  .contactListSecond {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20PX;
  }
  
  .contactListGrid {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20PX;
    width: 90%;
  }
  
  .contactRow {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .contactTopRow {
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }

  .contactBottomRow {
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-style: italic;
    text-align: center;
  }

  .contactListIconBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .dataText {
    margin: 2px;
  }

  .dataTextSecond {
    font-weight: 700;
  }

  .dataTextSecondUser {
    font-style: italic;
    font-weight: 600;
    border: solid;
    border-radius: 5px;
    padding: 5px;
    border-width: 1px;
    background-color: rgb(0, 0, 0);
    color: white;
  }

  .dataTextAddress {
    margin-top: 2px;
  }
  
  .editIcon {
    margin: 10px;
  }

  .contactRow.redBackground {
    background-color: rgba(255, 0, 0, 0.229) !important;
  }

  .orangeBackground {
    background-color: rgba(255, 166, 0, 0.355);
  }
  
  .greenBackground {
    background-color: rgba(0, 128, 0, 0.249);
  }

  .materialIconsBox {
    margin: 10px;
  }

  .materialIcon {
    margin-left: 2px;
    margin-right: 2px;
  }
  
  .customerSearch {
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.36);
    border-radius: 5px;
  }

  .filterButtons {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-around;
    min-width: 300px;
  }

  .search-options {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {


    .contactRow  {
      font-size: 12px;
      margin: 10px;
    }

    .contactListGrid {
      max-width: 100%;
    }
  
  }

  @media screen and (max-width: 500px) {


    .contactRow  {
      font-size: 8px;
      margin: 10px;
    }

    .contactListGrid {
      display: flex;
      flex-direction: column;
      gap: 0px;
      margin-top: 10PX;
    }

    .contactListSecond {
      margin-top: 10PX;
      margin-left: 5px;
      margin-right: 5px;
    }

    .contactListMain {
      margin-top: 100px;
    }

    .dataText {
      margin: 3px;
    }

    .dataTextSecondUser {
      padding: 3px;
      border-width: 0px;
    }
    
  }
  
  