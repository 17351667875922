.selected {
    background-color: rgb(26, 84, 106);
  }

  
.notes-calendar {
  background-color: rgb(0, 0, 0);
}

.notes-calendar .react-calendar__tile {
    background-color: #000000df;
    border: solid;
    border-color: rgb(255, 255, 255);
    border-width: 1px;
    cursor: auto;
    color: white;
    padding: 5px;
  }

  .notes-calendar .react-calendar__tile:hover {
    background-color: #444749df;
  }
  

.event-marker-notes {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2px;
    font-size: 10px;
    background-color: rgb(255, 255, 255); 
    color: black;
    justify-content: center;
    cursor: pointer;
    border-radius: 3px;
  }

  .iconDeleteNoteCrg:hover {
    cursor: pointer;
  }

  