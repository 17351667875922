.adminmain {
    margin-top: 100px;
    background-color: #81817b19;
  }
  
.chargeinputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 40px;
    border-top: 2px solid #ffffff;
    margin-top: 20PX;
  }

  .inputwrap {
    display: flex;
    flex-direction: row;
    align-items: center; 
    color: black;
    justify-content: space-between;
    margin-top: 20px;
  }

  .metalswrap {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    color: black;
    justify-content: space-between;
  }

input {
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 6px;
    font-size: 14px;
}


.pageheader {
    background-color: rgb(0, 0, 0);
    border-top: 2px solid #ffffff;
    color: white;
    width: 100%;
    font-size: 28px;
    margin-top: 20px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.table-container {
    margin-top: 20px;
    text-align: center;
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }
  
  thead th {
    background-color: #000000;
    border-bottom: 1px solid #ffffff;
    padding: 8px;
    color: rgb(255, 255, 255);
    box-shadow: -2px 5px 5px -2px rgba(0, 0, 0, 0.3);
  }
  
  tbody td {
    padding: 8px;
    border-bottom: 1px solid #add420;
    background-color: #81817b19;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  }
  
  tbody td input {
    width: 80%;
    border: none;
    padding: 6px;
    text-align: center;
    border: black;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    background-color: #81817b19;
  }


  button {
    background-color: white;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 3px;
  }

  button:hover {
    background-color: black;
    color: white;
  }
  
  .bottombutton {
    
    margin-bottom: 50px;
    background-color: white;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    width: 150px;
    background-color: #3d3d3519;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.3);
  }

  .bottombutton:hover {
    background-color: black;
    color: white;
  }
  
  .green {
    font-weight: bold;
    background-color: rgba(72, 241, 89, 0.89);
    border-radius: 5px;
    padding: 3px;
    border-color: black;
    border-style: solid;
    border-width: 1px;
  }
  
  .red {
    color: red;
  }

  .black {
    color: black;
  }
  
  

  @media screen and (max-width: 600px) {
    table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  
    thead,
    tbody,
    tr,
    th,
    td {
      text-align: left; 
      font-size: 12px;
    }
  
    tr {
      border-bottom: none;
    }
  
    th {
      height: 30px;
      line-height: 30px;
      font-weight: bold;
      font-size: 12px;
    }
  
    td {
      height: 25px;
      line-height: 25px;
      font-size: 12px;
    }
  
    tbody td input {
      width: auto;
      display: inline-block;
      font-size: 12px;
    }

    .chargeinputs {
        display: flex;
        flex-direction: column;
      }
      .inputwrap {
        display: flex;
        flex-direction: column;
      }
  }
  